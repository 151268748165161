import React, { useState } from "react";

import { useWallet } from "@services/metamask/WalletProvider.context";
import { getCurrentUser } from "@services/api/methods";

import OnlyDevGuard from "@guards/OnlyDevGuard/OnlyDevGuard";
import Button from "@components/toolkit/buttons/Button/Button";
import Stack from "@components/arrangement/Stack/Stack";

import "./Debug.scss";

const Debug = () => {
  const [user, setUser] = useState("");
  const [error, setError] = useState("");

  const { metamaskStatus, walletAddress, isOnCorrectChain, connectMetamask, handleSwitchChain } = useWallet();

  const handleGetUser = async () => {
    const res = await getCurrentUser();
    if (res?.status === 200) {
      setUser(JSON.stringify(res?.data));
    } else {
      setError(JSON.stringify(res?.data));
    }
  };

  return (
    <div className="debug-page">
      <h2>Debug page</h2>

      <br />
      <br />
      <Button onClick={connectMetamask}>Connect metamask</Button>
      <br />
      <Button onClick={handleGetUser}>get user</Button>
      <br />
      <Button onClick={handleSwitchChain}>switch chain</Button>

      <Stack rowGap={8} className="mt-10">
        <p className="p1">
          <b>Wallet status:</b> {metamaskStatus}
        </p>
        <p className="p1">
          <b>Wallet address:</b> {walletAddress}
        </p>
        <p className="p1">
          <b>User:</b> {user}
        </p>
        <p className="p1">
          <b>error:</b> {error}
        </p>
        <p className="p1">
          <b>isOnCorrectChain:</b> {String(isOnCorrectChain)}
        </p>

        <a href="https://metamask.app.link/dapp/https://skey.network">Otwórz skey.network w Metamask</a>
      </Stack>
    </div>
  );
};

export default (
  <OnlyDevGuard>
    <Debug />
  </OnlyDevGuard>
);
