import React from "react";
import { useTranslation } from "react-i18next";

import { BrowserStorageManager } from "@utils/browser/BrowserStorageManager";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";

import { NSFW_CHECKED_KEY } from "../AgeVerificationGuard";
import "./AgeVerificationModal.scss";

const PAGE_TO_REDIRECT_URL = "https://www.google.com";

interface AgeVerificationModalProps {
  onAbort?(): void;
  onConfirm?(): void;
}

const AgeVerificationModal = ({ onAbort, onConfirm }: AgeVerificationModalProps) => {
  const { t } = useTranslation("modals");

  const handleAbort = () => {
    BrowserStorageManager.writeLocalStorage(NSFW_CHECKED_KEY, false);
    onAbort?.();
    window.open(PAGE_TO_REDIRECT_URL, "_self");
  };

  const handleConfirm = () => {
    BrowserStorageManager.writeLocalStorage(NSFW_CHECKED_KEY, true);
    onConfirm?.();
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <div className="age-verification-modal">
            <img src="/images/layouts/age-verification-icon.svg" alt="Age verification" width={102} height={102} />
            <h3 className="mt-8">{t("ageVerificationModal.title")}</h3>
            <p className="p1 mt-5">{t("ageVerificationModal.description")}</p>

            <div className="mt-6 age-verification-modal-buttons">
              <ModalButton variant="secondary" onClick={handleAbort} className="reject-btn">
                {t("ageVerificationModal.buttons.no")}
              </ModalButton>

              <ModalButton onClick={handleConfirm} className="confirm-btn">
                {t("ageVerificationModal.buttons.yes")}
              </ModalButton>
            </div>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default AgeVerificationModal;
