type OrientationType = "landscape-primary" | "landscape-secondary" | "portrait-primary" | "portrait-secondary";

export enum OsName {
  Linux = "linux",
  MacOS = "mac_os",
  Unix = "unix",
  Windows = "windows",
  WindowsPhone = "Windows Phone",
  Android = "android",
  iOS = "ios",
  Unknown = "unknown"
}

export enum BrowserName {
  Edge = "Microsoft Edge",
  Chrome = "Chrome",
  Firefox = "Firefox",
  Safari = "Safari",
  Opera = "Opera",
  InternetExplorer = "Internet Explorer",
  Unknown = "Unknown"
}

type OsInfo = {
  name: OsName;
  version?: number[];
};

type BrowserInfo = {
  name: BrowserName;
  version?: number[];
};

type PlatformType = "desktop" | "tablet" | "mobile";

export class DeviceInfo {
  private static parseVersion(versionString: string): number[] {
    return versionString.split(".").map(Number).concat(Array(3).fill(0)).slice(0, 3);
  }

  private static detectOsInfo(): OsInfo {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    const osInfoRegex = /(Windows NT|Windows|Mac OS X|Android|iOS|Linux)(?: ([\d.]+))?/i;
    const match = userAgent.match(osInfoRegex);

    if (match) {
      const osName = match[1] as OsName;
      const osVersion = match[2] ? DeviceInfo.parseVersion(match[2]) : undefined;
      return { name: osName, version: osVersion };
    } else {
      return { name: OsName.Unknown, version: undefined };
    }
  }

  private static detectBrowserInfo(): BrowserInfo {
    const userAgent = navigator.userAgent;
    const browserInfoRegex = /(Edge|Chrome|Firefox|Safari|Opera|MSIE|Trident)\/([\d.]+)/i;
    const match = userAgent.match(browserInfoRegex);

    if (match) {
      const browserName = match[1] as BrowserName;
      const browserVersion = match[2] ? DeviceInfo.parseVersion(match[2]) : undefined;
      return { name: browserName, version: browserVersion };
    } else {
      return { name: BrowserName.Unknown, version: undefined };
    }
  }

  static isMobileDevice(): boolean {
    const userAgent = navigator.userAgent;
    const regex = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
    return regex.some((b) => userAgent.match(b));
  }

  static isTabletDevice(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return regex.test(userAgent);
  }

  static isDesktopDevice(): boolean {
    return !DeviceInfo.isMobileDevice() && !DeviceInfo.isTabletDevice();
  }

  static get platform(): PlatformType {
    if (DeviceInfo.isMobileDevice()) return "mobile";
    else if (DeviceInfo.isTabletDevice()) return "tablet";
    else return "desktop";
  }

  static get osInfo(): OsInfo {
    return DeviceInfo.detectOsInfo();
  }

  static get browserInfo(): BrowserInfo {
    return DeviceInfo.detectBrowserInfo();
  }

  static get screenOrientation(): OrientationType {
    const supportedScreenOrientation =
      (screen.orientation || {}).type || (screen as any).mozOrientation || (screen as any).msOrientation;
    const safariScreenOrientation: OrientationType =
      !screen.orientation && matchMedia("(orientation: portrait)").matches ? "portrait-primary" : "landscape-primary";
    return supportedScreenOrientation || safariScreenOrientation || "portrait-primary";
  }

  static get isAndroidDevice(): boolean {
    return DeviceInfo.detectOsInfo().name === OsName.Android;
  }

  static get isIosDevice(): boolean {
    return DeviceInfo.detectOsInfo().name === OsName.iOS;
  }
}
