export const supportedBrowsers = [
  {
    browser: "Chrome",
    path: "/images/browsers/chrome.png",
    alt: "Chrome browser logo"
  },
  {
    browser: "Firefox",
    path: "/images/browsers/firefox.png",
    alt: "Firefox browser logo"
  },
  {
    browser: "Brave",
    path: "/images/browsers/brave.png",
    alt: "Brave browser logo"
  },
  {
    browser: "Opera",
    path: "/images/browsers/opera.png",
    alt: "Opera browser logo"
  },
  {
    browser: "Edge",
    path: "/images/browsers/edge.png",
    alt: "Edge browser logo"
  }
];
