import { get } from "env-var";
import { ethNetworkConfig } from "./ethNetworkConfig";

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const APP_VERSION = require("../../package.json").version;

export class AppConfig {
  // App ==================================================================================
  static readonly APP_STAGE = get("REACT_APP_STAGE").asEnum(["prod", "dev"]);

  // API ==================================================================================
  static readonly GIRLS_API_URL = get("REACT_APP_GIRLS_API_URL").required().asUrlString();
  static readonly GIRLS_API_SOCKET_URL = get("REACT_APP_GIRLS_API_SOCKET_URL").required().asUrlString();

  // Blockchain ===========================================================================
  private static ETH_TOKEN_ADDRESS = get("REACT_APP_ETH_TOKEN_ADDRESS").required().asString();
  private static ETH_TOKEN_DECIMALS = get("REACT_APP_ETH_TOKEN_DECIMALS").default(8).asInt();
  private static ETH_TOKEN_SYMBOL = get("REACT_APP_ETH_TOKEN_SYMBOL").default("SETH").asString();

  static readonly ETH_NETWORK_KEY = get("REACT_APP_ETH_NETWORK_KEY")
    .default("dev")
    .asEnum(["mainnet", "sepolia", "dev"]);
  static readonly ETH_AUTH_SERVICE_NAME = get("REACT_APP_ETH_AUTH_SERVICE_NAME").default("test-aigirls").asString();

  static readonly ETH_NETWORK_CONFIG = ethNetworkConfig[this.ETH_NETWORK_KEY];
  static readonly ETH_TOKEN_CONFIG = {
    address: this.ETH_TOKEN_ADDRESS,
    decimals: this.ETH_TOKEN_DECIMALS,
    symbol: this.ETH_TOKEN_SYMBOL
  };

  // Other ================================================================================
  static readonly DISABLE_ESLINT_PLUGIN = get("DISABLE_ESLINT_PLUGIN").default("true").asBool();
  static readonly I18N_DEBUG_MODE = get("REACT_APP_I18N_DEBUG_MODE").default("false").asBool();
  static readonly GLOBAL_TIMEOUT = 1000 * 8; //[ms]
}
