import { useAppModals } from "@contexts/AppModals.context";

import ConnectToMetamask from "./ConnectToMetamask/ConnectToMetamask";
import DesktopDownloadMetamask from "./DesktopDownloadMetamask/DesktopDownloadMetamask";

const AppModals = () => {
  const { activeModal } = useAppModals();

  if (activeModal === null) return <></>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, data } = activeModal;

  if (type === "connectToMetamask") return <ConnectToMetamask />;
  if (type === "desktopDownloadMetamask") return <DesktopDownloadMetamask />;

  return <></>;
};

export default AppModals;
