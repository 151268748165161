import { SvgIcon } from "@src/types/SvgIcon.types";

const StarIcon = ({ width = 32, height = 32, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M5.5084 13.4895C5.16838 13.1804 5.35308 12.6215 5.81299 12.5679L12.3297 11.8083C12.5171 11.7864 12.6799 11.6703 12.759 11.5018L15.5076 5.64313C15.7016 5.22967 16.2995 5.22962 16.4935 5.64308L19.2421 11.5019C19.3212 11.6704 19.4829 11.7864 19.6704 11.8083L26.1874 12.5679C26.6473 12.6215 26.8319 13.1804 26.4919 13.4896L21.6742 17.8701C21.5357 17.9961 21.4738 18.1839 21.5106 18.3659L22.7892 24.6941C22.8795 25.1407 22.3961 25.486 21.992 25.2636L16.2657 22.1123C16.101 22.0217 15.9004 22.0216 15.7357 22.1122L10.0089 25.2637C9.60476 25.4861 9.12082 25.1407 9.21108 24.6941L10.4901 18.3658C10.5268 18.1838 10.4651 17.9961 10.3265 17.8701L5.5084 13.4895Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;
