import React from "react";

import { Router } from "@router/router";
import { welcome } from "@utils/welcome";

import "@translations/i18n";

import "@scss/utils.scss";
import "@scss/base.scss";
import "@scss/lib.scss";
import "@scss/variables.scss";
import "@scss/mixins.scss";
import "@scss/responsive.scss";

welcome();

const App = () => {
  return <Router />;
};

export default App;
