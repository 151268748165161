import React from "react";
import { useTranslation } from "react-i18next";

import { useAppModals } from "@contexts/AppModals.context";
import { useWallet } from "@services/metamask/WalletProvider.context";
import { useAuth } from "@contexts/Auth.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";
import Spinner from "@components/toolkit/Spinner/Spinner";

import "./ConnectToMetamask.scss";

const ConnectToMetamask = () => {
  const { metamaskStatus, connectMetamask } = useWallet();
  const { authStatus } = useAuth();
  // const { authStatus, login } = useAuth();
  const { closeModal } = useAppModals();

  const { t } = useTranslation(["modals", "common"]);

  const connecting = metamaskStatus === "connecting" || authStatus === "logging";

  const handleConnect = async () => {
    if (connecting) return;

    await connectMetamask().then(closeModal);
    // await connectMetamask().then(login).then(closeModal);
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <div className="connect-to-metamask">
            <img src="/images/blockchain/metamask-signet.svg" alt="" width={64} height={64} />

            <h3>{t("modals:connectToMetamask.title")}</h3>

            <p className="p1 description">{t("modals:connectToMetamask.description")}</p>

            <ModalButton onClick={handleConnect} className="mt-4">
              <img src="/images/blockchain/metamask-signet.svg" alt="" width={26} height={26} className="mr-3" />
              {t("common:connectMetamask")}
              {connecting && <Spinner className="ml-3" />}
            </ModalButton>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default ConnectToMetamask;
