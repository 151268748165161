import { SvgIcon } from "@src/types/SvgIcon.types";

const ChevronLeft = ({ width = 24, height = 24, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path d="M15 18L9 12L15 6" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChevronLeft;
