import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { CharacterTypes } from "@services/api/api.dtos";
import { getGirlCharacter } from "@services/api/utils/GirlPersonality";

import "./CharacterIcon.scss";

interface CharacterIconProps {
  character?: CharacterTypes;
  width?: number;
  height?: number;
  className?: string;
}

const CharacterIcon = ({ character = "fiery", width = 48, height = 48, className }: CharacterIconProps) => {
  const { t } = useTranslation("common");

  const girlCharacter = getGirlCharacter(character);
  const classes = classNames("character-icon", className);

  return (
    <img
      src={girlCharacter?.iconUrl}
      title={t(girlCharacter?.tKey as any)}
      alt={t(girlCharacter?.tKey as any)}
      width={width}
      height={height}
      className={classes}
    />
  );
};

export default CharacterIcon;
