import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import { AppConfig } from "@config/config";

const OnlyDevGuard = ({ children }: IChildren) => {
  if (AppConfig.APP_STAGE !== "dev") return <></>;

  return <>{children}</>;
};

export default OnlyDevGuard;
