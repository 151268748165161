import React from "react";

import "./TopCardSkeleton.scss";

const TopCardSkeleton = () => {
  return (
    <div className="top-card-skeleton skeleton-animation">
      <div className="image-skeleton">
        <div className="skeleton-animation" />
      </div>
      <div className="details-skeleton">
        <div className="skeleton-animation" />
      </div>
    </div>
  );
};

export default TopCardSkeleton;
