import React, { useEffect } from "react";

import { IChildren } from "@src/types/IChildren.types";
import { DeviceInfo } from "@utils/browser/deviceInfo";

import { useWallet } from "@services/metamask/WalletProvider.context";
import useBoolean from "@hooks/useBoolean";

import DesktopUnsupportedBrowser from "./DesktopUnsupportedBrowser/DesktopUnsupportedBrowser";
import MobileUnsupportedBrowser from "./MobileUnsupportedBrowser/MobileUnsupportedBrowser";

const SupportedBrowserGuard = ({ children }: IChildren) => {
  const [isModalOpen, openModal, closeModal] = useBoolean();
  const { metamaskStatus } = useWallet();

  const isSupportedBrowser = ["Firefox", "Chrome", "Brave", "Edge", "Opera", "OPR"].some((browser) =>
    navigator.userAgent.includes(browser)
  );

  const isDesktopDevice = DeviceInfo.isDesktopDevice();
  const isMobileDevice = DeviceInfo.isMobileDevice();

  const isUnsupportedBrowser =
    (!isSupportedBrowser && isDesktopDevice) || (metamaskStatus === "unavailable" && isMobileDevice);

  useEffect(() => {
    if (isUnsupportedBrowser) {
      openModal();
    } else {
      closeModal();
    }
  }, [isUnsupportedBrowser]);

  return (
    <>
      {children}
      {isModalOpen &&
        (isDesktopDevice ? (
          <DesktopUnsupportedBrowser onClose={closeModal} />
        ) : (
          <MobileUnsupportedBrowser onClose={closeModal} />
        ))}
    </>
  );
};

export default SupportedBrowserGuard;
