import { HTMLAttributeAnchorTarget, ReactNode } from "react";
import classNames from "classnames";

import "./Link.scss";

export type LinkVariants = "primary" | "secondary" | "tertiary";

export interface LinkProps {
  variant?: LinkVariants;
  title?: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  follow?: boolean;
  download?: string;
  disabled?: boolean;
  uppercase?: boolean;
  className?: string;
  children: ReactNode;
}

const Link = ({
  variant = "tertiary",
  title,
  href,
  target = "_self",
  follow = false,
  download,
  disabled = false,
  uppercase = true,
  className = "",
  children
}: LinkProps) => {
  const rel = follow ? "dofollow" : `noopener noreferrer nofollow`;
  const classes = classNames(`link link-${variant}`, { disabled: disabled, uppercase: uppercase }, className);

  return (
    <a href={href} target={target} rel={rel} title={title} className={classes} download={download}>
      {children}
    </a>
  );
};

export default Link;
