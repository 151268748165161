interface ChainProps {
  name: "mainnet" | "sepolia" | "dev";
  uiName: string;
  chainId: string;
  logoSrc: string;
}

export const ethNetworkConfig: Record<string, ChainProps> = {
  mainnet: {
    name: "mainnet",
    uiName: "Ethereum mainnet",
    chainId: "0x1",
    logoSrc: "/images/networks/ethereum.svg"
  },
  sepolia: {
    name: "sepolia",
    uiName: "Sepolia testnet",
    chainId: "0xaa36a7",
    logoSrc: "/images/networks/sepolia.png"
  },
  dev: {
    name: "dev",
    uiName: "Dev chain",
    chainId: "0x" + (1337).toString(16),
    logoSrc: "/images/networks/dev.svg"
  }
};
