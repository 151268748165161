import React from "react";
import { useTranslation } from "react-i18next";

import { getTKeyGirlCategory } from "@services/api/utils/GirlCategories";
import { CharacterTypes, Characters } from "@services/api/api.dtos";
import { GirlProfleProvider, useGirlProfle } from "@contexts/GirlProfile.context";

import MainLayout from "@components/layouts/MainLayout/MainLayout";
import LoadingScreen from "@app/LoadingScreen/LoadingScreen";

import ImageSlider from "@components/common/ImageSlider/ImageSlider";
import Button from "@components/toolkit/buttons/Button/Button";
import ChatIcon from "@components/assets/ChatIcon";
import ImageIcon from "@components/assets/ImageIcon";
import CharacterSticker from "@components/common/CharacterSticker/CharacterSticker";
import CharacterIcon from "@components/common/CharacterIcon/CharacterIcon";

import "./GirlProfile.scss";

const GirlProfile = () => {
  // const navigate = useNavigate();
  const { girl, status } = useGirlProfle();
  const { t } = useTranslation(["common"]);

  const loading = status === "loading";
  const categoryName = t(getTKeyGirlCategory(girl?.categories[0].category || "") as any);
  const dominanteCharacter = (girl?.characters[0].key as CharacterTypes) || Characters.FIERY;
  const images = [...(girl?.images || []), ...(girl?.blurredPhotos || [])];

  // const handleChatNow = () => {
  // navigate(`${PATHS.chats.path}?girl=${girl?.id}`);
  // window.history.replaceState(null, "", "/girls");
  // };

  if (loading) return <LoadingScreen />;

  return (
    <MainLayout withGoBack>
      <div className="girl-profile">
        <div className="profile-images">
          {girl?.images && <ImageSlider images={images} />}

          <div className="character-type-icon">
            <CharacterIcon character={dominanteCharacter} />
          </div>
        </div>

        <div className="profile-info">
          <div className="profile-info-content">
            <p className="p2 girl-category">{categoryName}</p>
            <h1 className="h2 girl-name">{girl?.name}</h1>

            <p className="p0 girl-description">{girl?.description}</p>

            <div className="girl-personality">
              <h4>{t("common:personality")}</h4>

              <div className="girl-personality-stickers">
                {girl?.characters.map(({ key, value }) => {
                  if (value <= 0) return <></>;
                  else
                    return (
                      <CharacterSticker key={`${girl.id}-${key}`} character={key as CharacterTypes} rate={value} />
                    );
                })}
              </div>
            </div>

            <hr className="profile-info-breakLine no-select" />

            <div className="profile-info-buttons">
              <Button fullWidth comingSoon>
                <ChatIcon /> {t("common:chatNow")}
              </Button>

              <Button variant="secondary" fullWidth comingSoon>
                <ImageIcon /> {t("common:generateImages")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default (
  <GirlProfleProvider>
    <GirlProfile />
  </GirlProfleProvider>
);
