import { SvgIcon } from "@src/types/SvgIcon.types";

const AffiliationIcon = ({ width = 25, height = 24, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M10.1667 18.2222C10.1667 18.6518 10.5149 19 10.9444 19H14.0556C14.4851 19 14.8333 18.6518 14.8333 18.2222V14.3333L18.7222 14.3333C19.1518 14.3333 19.5 13.9851 19.5 13.5556V10.4444C19.5 10.0149 19.1518 9.66667 18.7222 9.66667H14.8333L14.8333 5.77778C14.8333 5.34822 14.4851 5 14.0556 5H10.9444C10.5149 5 10.1667 5.34822 10.1667 5.77778V9.66667H6.27778C5.84822 9.66667 5.5 10.0149 5.5 10.4444V13.5556C5.5 13.9851 5.84822 14.3333 6.27778 14.3333H10.1667V18.2222Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AffiliationIcon;
