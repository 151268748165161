// DOCUMENTS ********************************************

export const PRIVACY_POLICY = "https://ayolai.gitbook.io/ayol.ai";

// LINKS ************************************************

export const WHITEPAPER = "https://ayolai.gitbook.io/ayol.ai";
export const AFFILIATE = "https://forms.gle/MECKnYddPeTQPLme8";
export const VIDEO_EXPLAINER = "#";
export const UNISWAP = "";
export const BUY_TOKEN = "";
export const AFFILIATION_NAVBAR = "";
export const ARTICLE_URL = "https://finance.yahoo.com/news/ayol-ai-shaping-future-human-163000776.html?guccounter=1";

// SOCIALS **********************************************

export const INSTAGRAM = "https://www.instagram.com/ayolai_official/";
export const TELEGRAM = "https://t.me/ayolai_official";
export const TWITTER = "https://twitter.com/AyolAiOfficial";

// METAMASK *********************************************
export const METAMASK_APP_GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=io.metamask";
export const METAMASK_APP_APP_STORE = "https://apps.apple.com/pl/app/metamask-blockchain-wallet/id1438144202";
export const METAMASK_BROWSER_EXTENSION = "https://metamask.io/download/";
export const OPEN_METAMASK_BROWSER_URL = "https://metamask.app.link/dapp/";
