import React from "react";

import "./GirlCardSkeleton.scss";

const GirlCardSkeleton = () => {
  return (
    <div className="girl-card-skeleton">
      <div className="skeleton-animation" />
    </div>
  );
};

export default GirlCardSkeleton;
