import React from "react";
import { useTranslation } from "react-i18next";

import { socialLinks } from "@constants/footerSocialLinks";
import { AFFILIATION_NAVBAR, BUY_TOKEN, UNISWAP } from "@constants/constants";
import { useAuth } from "@contexts/Auth.context";
import { useAppModals } from "@contexts/AppModals.context";
import { useWallet } from "@services/metamask/WalletProvider.context";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import IconLink from "@components/navigation/IconLink/IconLink";
import CloseIcon from "@components/assets/CloseIcon";
import WalletAddress from "../ConnectMetamask/WalletAddress/WalletAddress";

import "./MobileMenu.scss";

interface MobileMenuProps {
  onClose?(): void;
}

const MobileMenu = ({ onClose = () => {} }: MobileMenuProps) => {
  const { metamaskStatus, walletAddress } = useWallet();
  const { authStatus } = useAuth();
  const { openModal } = useAppModals();

  const { t } = useTranslation(["common", "layout"]);

  const connected = metamaskStatus === "connected" && authStatus !== "logging";
  const showWalletAddress = connected && walletAddress;

  const handleConnect = () => {
    if (metamaskStatus === "unavailable") {
      openModal({ type: "desktopDownloadMetamask" });
    } else {
      openModal({ type: "connectToMetamask" });
    }

    onClose();
  };

  return (
    <div className="mobile-menu">
      <div className="mobile-menu-box">
        <div
          className="mobile-menu-header"
          style={{ justifyContent: showWalletAddress ? "space-between" : "flex-end" }}
        >
          {showWalletAddress && <WalletAddress address={walletAddress} />}

          <button onClick={onClose} className="close-btn">
            <CloseIcon />
            {t("common:close")}
          </button>
        </div>

        <div className="mobile-menu-content">
          <Stack rowGap={40} className="menu-actions">
            <a
              href={BUY_TOKEN}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="mobile-menu-action pointer-events-none"
            >
              {t("layout:navbar.links.buyToken")}
            </a>

            {!connected && (
              <button onClick={handleConnect} className="mobile-menu-action">
                <img src="/images/blockchain/metamask-signet.svg" alt="Connect metamask" width={26} height={26} />
                {t("common:connectMetamask")}
              </button>
            )}

            <a
              href={UNISWAP}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="mobile-menu-action pointer-events-none"
              style={{ alignItems: "flex-end" }}
            >
              <img src="/images/blockchain/uniswap-signet.svg" alt="Uniswap signet" height={39} />
              {t("layout:navbar.links.uniswap")}
            </a>

            <a
              href={AFFILIATION_NAVBAR}
              target="_blank"
              className="mobile-menu-action mobile-menu-action-affiliation"
              rel="noreferrer"
            >
              <span>{t("layout:navbar.links.affiliation")}</span>
              <span className="coming-soon">{t("common:soon")}</span>
            </a>
          </Stack>

          <Group colGap={32} className="social-links">
            {socialLinks.map(({ title, href, icon }) => (
              <IconLink key={Math.random()} title={title} href={href} target="_blank">
                {icon}
              </IconLink>
            ))}
          </Group>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
