import { modalsContextFactory } from "./utils/ModalsContextFactory";

interface RemoveChatModal {
  type: "removeChat";
  data: {
    chatId: string;
  };
}

interface WithoutObjectModal {
  type: "connectToMetamask" | "desktopDownloadMetamask";
  data?: null;
}

export type AppModal = RemoveChatModal | WithoutObjectModal;

export const { ModalsProvider: AppModalsProvider, useModalsContext: useAppModals } = modalsContextFactory<AppModal>();
