import React, { useState } from "react";
import { IContext } from "@src/types/IContext.types";

export interface BaseModal {
  type: string;
  data?: Record<string, any> | null;
}

export interface ContextValue<T extends BaseModal> {
  activeModal: T | null;
  openModal: (modal: T) => void;
  closeModal: () => void;
}

export const modalsContextFactory = <T extends BaseModal>() => {
  const Context = React.createContext<ContextValue<T>>(null as any);

  const ModalsProvider = ({ children }: IContext) => {
    const [activeModal, setActiveModal] = useState<T | null>(null);

    const openModal: ContextValue<T>["openModal"] = ({ type, data }) =>
      setActiveModal({ type, data: data ?? null } as T);
    const closeModal = () => setActiveModal(null);

    const contextValue: ContextValue<T> = {
      activeModal,
      openModal,
      closeModal
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  };

  const useModalsContext = () => {
    const context = React.useContext(Context);

    if (context === undefined) {
      throw new Error("useModalsContext must be used within an ModalsProvider");
    }

    return context;
  };

  return { ModalsProvider, useModalsContext };
};
