import React from "react";

import MainSidebar from "@components/layoutElements/MainSidebar/MainSidebar";
import MobileToolbar from "@components/layoutElements/MobileToolbar/MobileToolbar";

import "./StickySidebarLayout.scss";

interface StickySidebarLayoutProps {
  children: React.ReactNode;
}

const StickySidebarLayout = ({ children }: StickySidebarLayoutProps) => {
  return (
    <div className="sticky-sidebar-layout">
      <div className="sidebar-part">
        <MainSidebar />
      </div>

      <main className="main-part">
        <div>{children}</div>
      </main>

      <div className="mobile-toolbar-part">
        <MobileToolbar />
      </div>
    </div>
  );
};

export default StickySidebarLayout;
