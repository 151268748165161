import { useEffect } from "react";

import { IChildren } from "@src/types/IChildren.types";
import useBoolean from "@hooks/useBoolean";
import { useWallet } from "@services/metamask/WalletProvider.context";

import SwitchNetwork from "./SwitchNetwork/SwitchNetwork";

const BlockchainNetworkGuard = ({ children }: IChildren) => {
  const [isModalOpen, openModal, closeModal] = useBoolean();

  const { metamaskStatus, isOnCorrectChain } = useWallet();

  useEffect(() => {
    if (metamaskStatus === "connected" && !isOnCorrectChain) openModal();
    else closeModal();
  }, [metamaskStatus, isOnCorrectChain]);

  return (
    <>
      {children}
      {isModalOpen && <SwitchNetwork onClose={closeModal} />}
    </>
  );
};

export default BlockchainNetworkGuard;
