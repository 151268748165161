import { useState, useRef, useCallback, RefObject } from "react";

const useDraggableScroll = (ref: RefObject<HTMLDivElement>) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftAtStart, setScrollLeftAtStart] = useState(0);

  const velocity = useRef(0);
  const animationFrameId = useRef(0);
  const lastX = useRef<number | null>(null);

  const animateScroll = useCallback(() => {
    if (!ref.current || Math.abs(velocity.current) < 0.5) {
      velocity.current = 0;
      cancelAnimationFrame(animationFrameId.current);
      return;
    }

    ref.current.scrollLeft += velocity.current;
    velocity.current *= 0.9; // Damping to speed
    animationFrameId.current = requestAnimationFrame(animateScroll);
  }, [ref]);

  const startDrag = useCallback(
    (pageX: number) => {
      if (!ref.current) return;

      setIsDragging(true);
      setStartX(pageX - ref.current.offsetLeft);
      setScrollLeftAtStart(ref.current.scrollLeft);
      velocity.current = 0;

      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      lastX.current = pageX;
    },
    [ref]
  );

  const onDrag = useCallback(
    (pageX: number) => {
      if (!isDragging || !ref.current) return;
      const currentX = pageX - ref.current.offsetLeft;
      const walk = startX - currentX;
      ref.current.scrollLeft = scrollLeftAtStart + walk;

      if (lastX.current !== null) {
        velocity.current = lastX.current - currentX;
      }

      lastX.current = currentX;
    },
    [isDragging, ref, startX, scrollLeftAtStart]
  );

  const endDrag = useCallback(() => {
    if (!isDragging || !ref.current) return;

    setIsDragging(false);
    animationFrameId.current = requestAnimationFrame(animateScroll);
  }, [isDragging, animateScroll]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      startDrag(e.pageX);
    },
    [startDrag]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      onDrag(e.pageX);
    },
    [onDrag]
  );

  const handleMouseUp = useCallback(() => {
    endDrag();
  }, [endDrag]);

  const handleMouseLeave = useCallback(() => {
    endDrag();
  }, [endDrag]);

  return {
    isDragging,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleMouseLeave
  };
};

export default useDraggableScroll;
