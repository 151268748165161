import React from "react";
import { useTranslation } from "react-i18next";

import { supportedBrowsers } from "@constants/supportedBrowsers";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";

import "./DesktopUnsupportedBrowser.scss";

interface ModalProps {
  onClose(): void;
}

const DesktopUnsupportedBrowser = ({ onClose }: ModalProps) => {
  const { t } = useTranslation(["modals", "common"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <div className="desktop-unsupported-browser">
            <h3 className="text-center mb-6">{t("modals:desktopUnsupportedBrowser.title")}</h3>
            <p className="p1 text-center mb-3">{t("modals:desktopUnsupportedBrowser.listBrowser")}</p>
            <Group rowGap={32} colGap={32} justifyContent="center" fullWidth className="mt-4" wrap="wrap">
              {supportedBrowsers.map(({ browser, path, alt }) => (
                <Stack key={Math.random()} rowGap={16} alignItems="center">
                  <img src={path} alt={alt} width={70} height={70} />
                  <p className="p1">{browser}</p>
                </Stack>
              ))}
            </Group>
            <ModalButton variant="secondary" onClick={onClose} className="desktop-unsupported-browser-modal-btn mt-6">
              {t("common:close")}
            </ModalButton>
          </div>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default DesktopUnsupportedBrowser;
