import { SvgIcon } from "@src/types/SvgIcon.types";

const ReloadIcon = ({ width = 24, height = 24, color = "#F7EEFE", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M10.3819 15.1541H6.33731V19.043M13.6176 8.93186H17.6622V5.04297M6 9.71228C6.45355 8.63293 7.21292 7.69755 8.19181 7.01248C9.1707 6.3274 10.3308 5.91993 11.5385 5.8367C12.7461 5.75347 13.9531 5.99762 15.0236 6.54145C16.0941 7.08527 16.9837 7.90716 17.5928 8.91329M18 14.374C17.5464 15.4534 16.7871 16.3888 15.8082 17.0738C14.8293 17.7589 13.6703 18.1659 12.4627 18.2491C11.2551 18.3323 10.0471 18.0882 8.97658 17.5444C7.90608 17.0006 7.01586 16.1788 6.40683 15.1727"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReloadIcon;
