import React, { CSSProperties } from "react";
import classNames from "classnames";

import "./TypingDots.scss";

interface TypingDotsProps {
  size?: number;
  className?: string;
}

const TypingDots = ({ size = 10, className }: TypingDotsProps) => {
  const classes = classNames("typing-dots", className);

  const dotStyle: CSSProperties = {
    width: size,
    height: size
  };

  return (
    <div className={classes}>
      <div className="dot" style={dotStyle} />
      <div className="dot" style={dotStyle} />
      <div className="dot" style={dotStyle} />
    </div>
  );
};

export default TypingDots;
