import { Outlet, RouteObject } from "react-router-dom";
import { MetaMaskProvider } from "metamask-react";

import { PATHS } from "../paths";

import AgeVerificationGuard from "@guards/AgeVerificationGuard/AgeVerificationGuard";
import OfflineNetworkGuard from "@guards/OfflineNetworkGuard/OfflineNetworkGuard";
import SupportedBrowserGuard from "@guards/SupportedBrowserGuard/SupportedBrowserGuard";
import SwitchNetworkGuard from "@guards/SwitchNetworkGuard/SwitchNetworkGuard";
// import AuthGuard from "@guards/AuthGuard/AuthGuard";

import { WalletProvider } from "@services/metamask/WalletProvider.context";
import { AuthProvider } from "@contexts/Auth.context";
import { AppModalsProvider } from "@contexts/AppModals.context";
import { GirlsDataProvider } from "@contexts/GirlsData.context";
// import { OnlineUsersCountProvider } from "@contexts/OnlineUsersCount.context";
// import { ChatsProvider } from "@contexts/Chats.context";
// import { ActiveChatProvider } from "@contexts/ActiveChat.context";
// import { GirlSocketProvider } from "@services/websocket/GirlSocketContext";

import AppModals from "@components/modals/AppModals";
// import ChatsModals from "@components/modals/ChatsModals";

import GirlsPage from "@app/GirlsPage/GirlsPage";
import GirlProfile from "@app/GirlProfile/GirlProfile";
// import Chats from "@app/Chats/Chats";
// import Chat from "@app/Chat/Chat";
import Debug from "@app/Debug/Debug";
import NotFound from "@app/NotFound/NotFound";

export const mainRoutes: RouteObject = {
  element: <Outlet />,
  children: [
    {
      path: PATHS.main.path,
      element: GirlsPage
    },
    {
      path: PATHS.girlProfile.path,
      element: GirlProfile
    }
    // {
    //   element: (
    //     <AuthGuard>
    //       <GirlSocketProvider>
    //         <ChatsProvider>
    //           <ActiveChatProvider>
    //             <Outlet />
    //             <ChatsModals />
    //           </ActiveChatProvider>
    //         </ChatsProvider>
    //       </GirlSocketProvider>
    //     </AuthGuard>
    //   ),
    //   children: [
    //     {
    //       path: PATHS.chats.path,
    //       element: Chats
    //     },
    //     {
    //       path: PATHS.chat.path,
    //       element: Chat
    //     }
    //   ]
    // }
  ]
};

export const otherRoutes: RouteObject = {
  element: <Outlet />,
  children: [
    {
      path: PATHS.debug.path,
      element: Debug
    },
    {
      path: PATHS.notFound.path,
      element: NotFound
    }
  ]
};

export const appRoutes: RouteObject = {
  element: (
    <AgeVerificationGuard>
      <OfflineNetworkGuard>
        <MetaMaskProvider>
          <WalletProvider>
            <AuthProvider>
              <AppModalsProvider>
                <SupportedBrowserGuard>
                  <SwitchNetworkGuard>
                    {/* <OnlineUsersCountProvider> */}
                    <GirlsDataProvider>
                      <Outlet />
                      <AppModals />
                    </GirlsDataProvider>
                    {/* </OnlineUsersCountProvider> */}
                  </SwitchNetworkGuard>
                </SupportedBrowserGuard>
              </AppModalsProvider>
            </AuthProvider>
          </WalletProvider>
        </MetaMaskProvider>
      </OfflineNetworkGuard>
    </AgeVerificationGuard>
  ),
  children: [mainRoutes, otherRoutes]
};
