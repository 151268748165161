import { SvgIcon } from "@src/types/SvgIcon.types";

const ShoppingCart = ({ width = 24, height = 24, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M6 6H6.18162C6.50229 6 6.66291 6 6.7937 6.05699C6.90901 6.10723 7.0076 6.18812 7.07869 6.29069C7.1592 6.40684 7.18827 6.56193 7.24629 6.87152L8.70734 14.6667L15.7611 14.6667C16.0678 14.6667 16.2217 14.6667 16.3487 14.6133C16.461 14.5661 16.5577 14.4897 16.6296 14.3926C16.7109 14.2828 16.7454 14.1358 16.8142 13.8421L16.8147 13.84L17.8768 9.30664L17.877 9.30569C17.9814 8.8601 18.0337 8.63677 17.9763 8.46159C17.9259 8.30788 17.8198 8.17756 17.6791 8.09461C17.5186 8 17.2866 8 16.8216 8H7.69207M16.1524 18C15.7786 18 15.4756 17.7015 15.4756 17.3333C15.4756 16.9651 15.7786 16.6667 16.1524 16.6667C16.5262 16.6667 16.8292 16.9651 16.8292 17.3333C16.8292 17.7015 16.5262 18 16.1524 18ZM9.38414 18C9.01034 18 8.70731 17.7015 8.70731 17.3333C8.70731 16.9651 9.01034 16.6667 9.38414 16.6667C9.75794 16.6667 10.061 16.9651 10.061 17.3333C10.061 17.7015 9.75794 18 9.38414 18Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShoppingCart;
