import { SvgIcon } from "@src/types/SvgIcon.types";

const CarouselChevronRight = ({ width = 24, height = 24, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke={color}
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 6l6 6l-6 6" />
    </svg>
  );
};

export default CarouselChevronRight;
