import { SvgIcon } from "@src/types/SvgIcon.types";

const HeartIcon = ({ width = 32, height = 32, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M15.9998 11.4257C13.9258 6.32732 6.6665 6.87034 6.6665 13.3866C6.6665 19.9029 15.9998 25.3333 15.9998 25.3333C15.9998 25.3333 25.3332 19.9029 25.3332 13.3866C25.3332 6.87034 18.0739 6.32732 15.9998 11.4257Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeartIcon;
