import { SiweMessage } from "./IAuth";

export class AuthBase {
  static readonly SIWE_TIMEOUT = 60;
  static readonly VERSION = 1;

  constructor(protected getTime = AuthBase.defaultGetTime) {}

  // EIP-4361
  protected stringifySiweMessage(msg: SiweMessage) {
    const chunks = [
      `${msg.domain} wants you to sign in with your Ethereum account:\n`,
      `${msg.address}\n\n`,
      `${msg.serviceName}\n\n`,
      `URI: ${msg.uri}\n`,
      `Version: ${msg.version}\n`,
      `Chain ID: ${msg.chainId}\n`,
      `Nonce: ${msg.nonce}\n`,
      `Issued At: ${new Date(msg.iat * 1000).toISOString()}\n`,
      `Expiration Time: ${new Date(msg.exp * 1000).toISOString()}`
    ];

    return chunks.join("");
  }

  private static defaultGetTime = () => {
    return Math.floor(Date.now() / 1000);
  };
}
