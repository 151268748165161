import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";

import { IContext } from "@src/types/IContext.types";
import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { GirlDetailsDto } from "@services/api/api.dtos";
import { getGirlProfile } from "@services/api/methods";
import { sleep } from "@utils/sleep";

interface GirlProfileData {
  status: FetchStatus;
  girl: GirlDetailsDto | null;
  error: Error | AxiosError | null;
}

interface ContextValue extends GirlProfileData {
  fetchGirlProfile: (girlId: string) => Promise<AxiosResponse<GirlDetailsDto, any> | undefined>;
}

const initialContextData = (): GirlProfileData => ({
  status: "loading",
  girl: null,
  error: null
});

const GirlProfileContext = React.createContext(null as any);

export const GirlProfleProvider = ({ children }: IContext) => {
  const [profileData, setProfileData] = useState<GirlProfileData>(initialContextData());
  const { girlId } = useParams();

  useEffect(() => {
    if (!girlId) return;
    fetchGirlProfile(girlId);
  }, [girlId]);

  const fetchGirlProfile = async (girlId: string) => {
    try {
      initialContextData();

      await sleep(500);
      const res = await getGirlProfile(girlId);

      if (res.status === 200) {
        setProfileData((prevState) => ({ ...prevState, girl: res.data, status: "success" }));
      }

      return res;
    } catch (e: any) {
      setProfileData((prevState) => ({ ...prevState, status: "failed", error: e }));
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const contextValue: ContextValue = {
    ...profileData,
    fetchGirlProfile
  };

  return <GirlProfileContext.Provider value={contextValue}>{children}</GirlProfileContext.Provider>;
};

export const useGirlProfle = (): ContextValue => {
  const context = React.useContext(GirlProfileContext);

  if (context === undefined) {
    throw new Error("useGirlProfle must be used within an GirlProfleProvider");
  }

  return context;
};
