import { RefObject, useCallback, useEffect, useState } from "react";

const useScrollButtons = (ref: RefObject<HTMLDivElement>) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const updateScrollButtons = useCallback(() => {
    if (!ref.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = ref.current;

    const isScrollable = scrollWidth > clientWidth;

    setCanScrollLeft(isScrollable && scrollLeft > 0);
    setCanScrollRight(isScrollable && scrollLeft < scrollWidth - clientWidth);
  }, [ref]);

  useEffect(() => {
    window.addEventListener("resize", updateScrollButtons);
    ref.current && ref.current.addEventListener("scroll", updateScrollButtons);

    updateScrollButtons();

    return () => {
      window.removeEventListener("resize", updateScrollButtons);
      ref.current && ref.current.removeEventListener("scroll", updateScrollButtons);
    };
  }, [updateScrollButtons]);

  const scroll = (direction: "left" | "right") => {
    if (ref.current) {
      const container = ref.current;
      const childrenArray = Array.from(container.children);
      const containerRect = container.getBoundingClientRect();

      let targetElement = null;
      let scrollAmount = 0;

      if (direction === "right") {
        // Scroll right
        for (const child of childrenArray) {
          const childRect = child.getBoundingClientRect();
          // Find the first tab that is partially outside the container view
          if (childRect.right > containerRect.right) {
            targetElement = child;
            break;
          }
        }

        if (targetElement) {
          // Scroll to the left edge of a partially visible tab
          scrollAmount = targetElement.getBoundingClientRect().left - containerRect.left;
        } else {
          // Scroll to the end if there is no tab partially visible
          scrollAmount = container.scrollWidth - container.scrollLeft - containerRect.width;
        }
      } else {
        // Scroll left
        for (const child of childrenArray) {
          const childRect = child.getBoundingClientRect();
          if (childRect.right <= containerRect.left) {
            targetElement = child;
          } else {
            break;
          }
        }

        if (!targetElement) {
          // If there is no partially visible tab on the left, scroll to the beginning
          scrollAmount = -container.scrollLeft;
        } else {
          // Scroll to the right edge of the previous partially visible tab
          scrollAmount = targetElement.getBoundingClientRect().right - containerRect.left - containerRect.width;
        }
      }

      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return { canScrollLeft, canScrollRight, updateScrollButtons, scroll };
};

export default useScrollButtons;
