import { TransactionRequest, ethers } from "ethers";

export class MetamaskClient {
  public static async sendTransaction(tx: TransactionRequest) {
    const ethersProvider = this.getProvider();
    const signer = await ethersProvider.getSigner();
    await signer.sendTransaction(tx);
  }

  public static async getNativeBalance(walletAddress: string) {
    const provider = this.getProvider();

    try {
      const balance = await provider.getBalance(walletAddress);
      return balance;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return 0n;
    }
  }

  public static async getERC20Balance(assetAddress: string, walletAddress: string) {
    const provider = this.getProvider();

    const abi = ethers.AbiCoder.defaultAbiCoder();

    const selector = ethers.id("balanceOf(address)").slice(2, 10);
    const args = abi.encode(["address"], [walletAddress]).slice(2);
    const data = `0x${selector}${args}`;

    const res = await provider.call({ to: assetAddress, data, from: ethers.ZeroAddress });

    try {
      const balance = abi.decode(["uint256"], res)[0];
      return balance;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return 0n;
    }
  }

  private static getProvider() {
    return new ethers.BrowserProvider(window.ethereum);
  }
}
