import { BigNumberish, ethers } from "ethers";

export const numberToBigNumber = (amount: string, decimals: number) => {
  try {
    const num = Number(amount);
    const notANumber = isNaN(num);
    if (notANumber || !Number.isFinite(num)) throw new Error(`Couldn't parse number ${amount}`);

    return ethers.parseUnits(amount, decimals).toString();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const bigNumberToNumber = (value: BigNumberish, decimals: number) => {
  return Number(ethers.formatUnits(value, decimals));
};

export const bigNumberToPrecision = (bigNumber: bigint, numberDecimals: number, significantDecimals: number) => {
  if (bigNumber === 0n) return 0;

  if (
    numberDecimals < 0 ||
    significantDecimals < 0 ||
    typeof numberDecimals !== "number" ||
    typeof significantDecimals !== "number"
  ) {
    throw new Error("Both numberDecimals and significantDecimals must be positive numbers.");
  }

  if (significantDecimals > numberDecimals) {
    throw new Error("Significant decimals cannot be greater than total number of decimals.");
  }

  const decimalsBigInt = 10n ** BigInt(numberDecimals - significantDecimals);
  const divided = bigNumber / decimalsBigInt;
  const asNumber = Number(divided);
  return asNumber / 10 ** significantDecimals;
};
