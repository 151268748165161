import { SvgIcon } from "@src/types/SvgIcon.types";

const ImageIcon = ({ width = 24, height = 24, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M4.00004 15.7501C4 15.7016 4 15.6517 4 15.6001V8.40015C4 7.56007 4 7.13972 4.19377 6.81885C4.36421 6.5366 4.63598 6.3073 4.97049 6.16349C5.35077 6 5.84897 6 6.84462 6H17.1557C18.1514 6 18.6489 6 19.0292 6.16349C19.3637 6.3073 19.636 6.5366 19.8064 6.81885C20 7.1394 20 7.55925 20 8.39768V15.6023C20 15.9661 20 16.2509 19.9842 16.4831M4.00004 15.7501C4.00073 16.4913 4.01189 16.8794 4.19377 17.1805C4.36421 17.4628 4.63598 17.6929 4.97049 17.8367C5.3504 18 5.84799 18 6.8417 18H17.1587C18.1524 18 18.6493 18 19.0292 17.8367C19.3637 17.6929 19.636 17.4628 19.8064 17.1805C19.916 16.9991 19.9636 16.786 19.9842 16.4831M4.00004 15.7501L8.2382 11.5781L8.23945 11.577C8.61535 11.2069 8.80361 11.0216 9.02691 10.9548C9.22306 10.8962 9.43721 10.8975 9.63238 10.9585C9.85479 11.028 10.0401 11.2154 10.4106 11.5906L12.785 13.9946C13.128 14.3419 13.3005 14.5166 13.5099 14.5867C13.6946 14.6485 13.8984 14.6555 14.089 14.608C14.306 14.554 14.497 14.3928 14.8789 14.0706L15.3207 13.6978C15.7096 13.3696 15.9041 13.2057 16.1242 13.1521C16.3175 13.105 16.5241 13.1151 16.7101 13.1799C16.9218 13.2537 17.0935 13.4343 17.4371 13.7966L19.9842 16.4831M19.9842 16.4831L20 16.4997M14.6667 10.5C14.1757 10.5 13.7778 10.1642 13.7778 9.75C13.7778 9.33579 14.1757 9 14.6667 9C15.1576 9 15.5556 9.33579 15.5556 9.75C15.5556 10.1642 15.1576 10.5 14.6667 10.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageIcon;
