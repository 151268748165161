import React, { memo } from "react";
import classNames from "classnames";

import Group from "@components/arrangement/Group/Group";

import { ModalButtonProps } from "./ModalButton.types";
import "./ModalButton.scss";

const ModalButton = ({
  type = "button",
  variant = "primary",
  title = "",
  disabled = false,
  loading = false,
  uppercase = true,
  fullWidth = false,
  testid = "",
  className = "",
  children,
  onClick = () => {},
  onBlur = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}: ModalButtonProps) => {
  const classes = classNames(
    `modal-button modal-button-${variant}`,
    {
      "modal-button-loading": loading,
      uppercase: uppercase,
      "full-width": fullWidth
    },
    className
  );

  return (
    <button
      type={type}
      title={title}
      data-testid={testid}
      disabled={disabled || loading}
      className={classes}
      onClick={onClick}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Group colGap={2} alignItems="center" justifyContent="center" fullSize>
        {children}
      </Group>
    </button>
  );
};

export default memo(ModalButton);
