import React, { useEffect, useMemo, useState } from "react";
import { useMetaMask } from "metamask-react";
import { AddEthereumChainParameter } from "metamask-react/lib/metamask-context";

import { IContext } from "@src/types/IContext.types";
import { AppConfig } from "@config/config";
import useBalance from "./hooks/useBalance";

type MetamaskStatus = "initializing" | "unavailable" | "notConnected" | "connecting" | "connected";

interface ContextValue {
  metamaskAvailable: boolean;
  metamaskStatus: MetamaskStatus;
  walletAddress: string | null;
  isOnCorrectChain: boolean;
  accountBalance: {
    bigint: bigint;
    number: number;
  };
  checkMetamaskAvailability: () => boolean;
  connectMetamask: () => Promise<string[] | null | undefined>;
  addChain: (parameters: AddEthereumChainParameter) => Promise<void>;
  handleSwitchChain: () => Promise<void>;
  fetchBalance: () => Promise<void>;
}

const WalletContext = React.createContext(null as any);

export const WalletProvider = ({ children }: IContext) => {
  const [metamaskAvailable, setMetamaskAvailable] = useState(false);
  const { status, account, connect, switchChain, chainId, addChain } = useMetaMask();
  const { accountBalance, fetchBalance } = useBalance(account, chainId);

  const isOnCorrectChain = useMemo(() => AppConfig.ETH_NETWORK_CONFIG.chainId === chainId, [chainId]);
  const metamaskStatus = useMemo(() => status, [status]);
  const walletAddress = useMemo(() => account, [account]);

  const checkMetamaskAvailability = () => {
    return window.ethereum && window.ethereum.isMetaMask ? true : false;
  };

  const connectMetamask = async () => {
    try {
      return await connect();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleSwitchChain = async (chainId?: string) => {
    try {
      const newChainId = chainId || AppConfig.ETH_NETWORK_CONFIG.chainId;
      await switchChain(newChainId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    const available = checkMetamaskAvailability();
    setMetamaskAvailable(available);
  }, []);

  const contextValue: ContextValue = {
    metamaskAvailable,
    metamaskStatus,
    walletAddress,
    isOnCorrectChain,
    accountBalance,
    checkMetamaskAvailability,
    connectMetamask,
    addChain,
    handleSwitchChain,
    fetchBalance
  };

  return <WalletContext.Provider value={contextValue}>{children}</WalletContext.Provider>;
};

export const useWallet = (): ContextValue => React.useContext(WalletContext);
