import { SvgIcon } from "@src/types/SvgIcon.types";

const ChatIcon = ({ width = 32, height = 32, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M11.6774 25.3357C13.1457 26.1825 14.8493 26.667 16.6661 26.667C22.189 26.667 26.6665 22.1899 26.6665 16.667C26.6665 11.1442 22.1894 6.66699 16.6665 6.66699C11.1437 6.66699 6.6665 11.1442 6.6665 16.667C6.6665 18.4838 7.151 20.1875 7.99783 21.6558L8.00111 21.6615C8.08259 21.8027 8.12368 21.874 8.14229 21.9413C8.15984 22.0048 8.16475 22.0619 8.16025 22.1276C8.15542 22.1983 8.13162 22.2715 8.08282 22.4179L7.22857 24.9807L7.22749 24.984C7.04726 25.5247 6.95714 25.7951 7.02137 25.9752C7.07738 26.1323 7.20171 26.2563 7.35878 26.3123C7.53852 26.3764 7.80767 26.2867 8.346 26.1072L8.3527 26.1047L10.9154 25.2505C11.0614 25.2018 11.1355 25.1772 11.2061 25.1724C11.2718 25.1679 11.3285 25.1738 11.392 25.1913C11.4595 25.21 11.5308 25.2511 11.6728 25.333L11.6774 25.3357Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
