import { Signer, ethers } from "ethers";

import { AuthBase } from "./AuthBase";
import { AuthClientOptions, LoginMessage, SiweMessage } from "./IAuth";

export class AuthClient extends AuthBase {
  constructor(private signer: Signer, private options: AuthClientOptions) {
    super(options.timeProvider);
  }

  async createLoginMessage(): Promise<LoginMessage> {
    const siwe = await this.createSiweMessage();
    const sig = await this.signSiwe(siwe);

    return { siwe, signature: sig };
  }

  private async createSiweMessage(): Promise<SiweMessage> {
    const iat = this.getTime();

    return {
      ...this.getLocationData(),
      serviceName: this.options.serviceName,
      address: await this.signer.getAddress(),
      version: AuthClient.VERSION,
      chainId: await this.getChainId(),
      nonce: this.randomNonce(),
      iat,
      exp: iat + AuthBase.SIWE_TIMEOUT
    };
  }

  private getLocationData() {
    if (this.options.environment === "browser") {
      return {
        domain: window.location.host,
        uri: window.location.origin + window.location.pathname
      };
    } else {
      return {
        domain: "localhost",
        uri: "http://localhost"
      };
    }
  }

  private async signSiwe(siwe: SiweMessage) {
    return await this.signer.signMessage(this.stringifySiweMessage(siwe));
  }

  private randomNonce() {
    return ethers.hexlify(ethers.randomBytes(16));
  }

  private async getChainId() {
    const network = await this.signer.provider?.getNetwork();
    return Number(network?.chainId.toString() ?? "0");
  }
}
