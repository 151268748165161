import React, { memo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { BaseProps } from "@src/types/BaseProps.types";

import "./Button.scss";

interface ButtonProps extends BaseProps<HTMLButtonElement, { size?: "normal" | "small" }> {
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "secondary" | "tertiary";
  children: React.ReactNode | React.ReactElement;
  loading?: boolean;
  uppercase?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
  onClick?(): void;
  onBlur?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

const Button = ({
  type = "button",
  variant = "primary",
  disabled = false,
  loading = false,
  uppercase = true,
  fullWidth = false,
  comingSoon = false,
  className = "",
  children,
  onClick = () => {},
  onBlur = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  ...props
}: ButtonProps) => {
  const { t } = useTranslation("common");

  const classes = classNames(
    `button button-${variant}`,
    comingSoon && `button-${variant}-coming-soon`,
    {
      "button-coming-soon": comingSoon,
      "button-loading": loading,
      uppercase: uppercase,
      "full-width": fullWidth
    },
    className
  );

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={classes}
      onClick={onClick}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      {children}

      {comingSoon && <span className="coming-soon">{t("soon")}</span>}
    </button>
  );
};

export default memo(Button);
