import React, { CSSProperties } from "react";
import classNames from "classnames";

import { BaseProps } from "@src/types/BaseProps.types";
import "./Stack.scss";

interface StackProps extends BaseProps<HTMLDivElement> {
  children: React.ReactNode;
  gap?: number;
  rowGap?: number;
  colGap?: number;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  flexWrap?: CSSProperties["flexWrap"];
  fullSize?: boolean;
  fullWidth?: boolean;
  equalWidth?: boolean;
  className?: string;
}

const getFlexGap = (gap?: number, rowGap?: number, colGap?: number) => {
  if (rowGap !== undefined || colGap !== undefined) {
    return `${rowGap || 0}px ${colGap || 0}px`;
  }

  return `${gap ? gap : 0}px`;
};

const Stack = ({
  children,
  gap,
  rowGap,
  colGap,
  justifyContent = "flex-start",
  alignItems = "flex-start",
  flexWrap,
  fullSize,
  fullWidth,
  equalWidth,
  className = "",
  ...props
}: StackProps) => {
  const flexGap = getFlexGap(gap, rowGap, colGap);

  const styles = { gap: flexGap, justifyContent, alignItems, flexWrap, ...props.style };

  const classes = classNames(
    "stack",
    { "full-size": fullSize, "full-width": fullWidth, "equal-width": equalWidth },
    className
  );

  return (
    <div style={styles} className={classes} {...props}>
      {children}
    </div>
  );
};

export default Stack;
