import React from "react";
import { useTranslation } from "react-i18next";

import "./NotFound.scss";

const NotFound = () => {
  const { t } = useTranslation("layout");

  return (
    <div className="not-found">
      <div className="not-found-content">
        <img src="/images/branding/ayol-ai-full-logo.svg" alt="Ayol.ai" width={160} height={52} className="mb-4" />

        <div className="not-found-bg">
          <img src="/images/layouts/not-found-bg.svg" alt="page not found" className="bg-image" />
        </div>

        <div className="not-found-info">
          <p className="p1 semi-bold text-uppercase">{t("notFound.errorBase")}</p>
          <h1 className="display-1">404.</h1>
          <p className="error-description">{t("notFound.errorDescription")}</p>
        </div>
      </div>
    </div>
  );
};

export default (
  <>
    <NotFound />
  </>
);
