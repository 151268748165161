import { useLayoutEffect, useEffect, useState } from "react";

const getScrollBarWidth = () => {
  const elem = document.createElement("div");
  elem.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
  document.body.appendChild(elem);

  const width = elem.offsetWidth - elem.clientWidth;
  elem.remove();

  return width;
};

const useBlockScroll = (initialValue?: boolean) => {
  const [locked, setLocked] = useState(!!initialValue);

  const handleLocked = () => setLocked(true);
  const handleUnlocked = () => setLocked(false);
  const handleToggleLocked = () => setLocked((prevState) => !prevState);

  useLayoutEffect(() => {
    if (!locked) return;

    const originalOverflow = document.body.style.overflow;
    const originalPaddingRight = document.body.style.paddingRight;

    document.body.style.overflow = "hidden";

    const scrollbarVisible = document.body.scrollHeight > document.body.clientHeight;

    if (scrollbarVisible) {
      const scrollBarWidth = getScrollBarWidth();
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      document.body.style.overflow = originalOverflow;

      if (scrollbarVisible) {
        document.body.style.paddingRight = originalPaddingRight;
      }
    };
  }, [locked]);

  useEffect(() => {
    if (locked !== initialValue) {
      setLocked(!!initialValue);
    }
  }, [initialValue]);

  return { locked, handleLocked, handleUnlocked, handleToggleLocked };
};

export default useBlockScroll;
