import React from "react";
import { useTranslation } from "react-i18next";

import { ARTICLE_URL } from "@constants/constants";

import Stack from "@components/arrangement/Stack/Stack";
import ArrowUpRight from "@components/assets/ArrowUpRight";
import Link from "@components/navigation/Link/Link";

import "./ReadArticle.scss";

const ReadArticle = () => {
  const { t } = useTranslation("mainPage");

  return (
    <div className="read-article">
      <img
        src="/images/layouts/read-article-bg-left.png"
        alt="Article section background"
        height={322}
        className="read-article-bg-left"
      />
      <img
        src="/images/layouts/read-article-bg-right.png"
        alt="Article section background"
        height={322}
        className="read-article-bg-right"
      />
      <img
        src="/images/layouts/read-article-bg-mobile.png"
        alt="Article section background"
        width={496}
        className="read-article-bg-mobile"
      />

      <Stack alignItems="center">
        <h1>{t("readArticle.title")}</h1>

        <Link href={ARTICLE_URL} target="blank" variant="primary" className="mt-7">
          {t("readArticle.button")} <ArrowUpRight />
        </Link>
      </Stack>
    </div>
  );
};

export default ReadArticle;
