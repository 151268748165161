import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import TypingDots from "@components/common/TypingDots/TypingDots";

import "./LoadingScreen.scss";

interface LoadingScreenProps {
  className?: string;
}

const LoadingScreen = ({ className }: LoadingScreenProps) => {
  const { t } = useTranslation("common");

  const classes = classNames("loading-screen", className);

  return (
    <div className={classes}>
      <TypingDots />

      <p className="p0 loading-screen-text">{t("loading")}</p>
    </div>
  );
};

export default LoadingScreen;
