import { INSTAGRAM, TELEGRAM, TWITTER } from "./constants";

import Instagram from "@components/assets/socials/Instagram";
import Telegram from "@components/assets/socials/Telegram";
import Twitter from "@components/assets/socials/Twitter";

export const socialLinks = [
  {
    title: "X",
    href: TWITTER,
    icon: <Twitter />,
    sidebarIcon: <Twitter width={32} height={32} color="#bab5be" />
  },
  {
    title: "Telegram",
    href: TELEGRAM,
    icon: <Telegram />,
    sidebarIcon: <Telegram width={32} height={32} color="#bab5be" />
  },
  {
    title: "Instagram",
    href: INSTAGRAM,
    icon: <Instagram />,
    sidebarIcon: <Instagram width={32} height={32} color="#bab5be" />
  }
];
