import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ethers } from "ethers";

import { AppConfig } from "@config/config";
import { AuthDto } from "./api.dtos";

import { CookieManager } from "@utils/browser/CookieManager";
import { ApiClientOptions, AxiosClient } from "@services/axios/AxiosClient";
import { AuthClient } from "@services/eth-auth";

export const GIRL_API_ACCESS_TOKEN_KEY = "GIRL_API_ACCESS_TOKEN_KEY";
export const GIRL_API_TOKEN_EXP_KEY = "GIRL_API_TOKEN_EXP_KEY";

export const GIRL_API_ACCESS_TOKEN_HEADER = "x-aigirls-access-token";

export class GirlApiClient extends AxiosClient {
  constructor(options: ApiClientOptions) {
    super(options);
  }

  public async sendRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return await super.sendRequest<T>(config);
  }

  public async sendAuthedRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const accessToken = await this.getJWT();

      const headers = {
        ...config.headers,
        [GIRL_API_ACCESS_TOKEN_HEADER]: accessToken.token
      };

      const res = await super.sendRequest<T>({
        ...config,
        headers
      });

      return res;
    } catch (e: any) {
      return e;
    }
  }

  public async getJWT(): Promise<AuthDto> {
    const accessToken = CookieManager.get(GIRL_API_ACCESS_TOKEN_KEY) || "";
    const tokenExp = Number(CookieManager.get(GIRL_API_TOKEN_EXP_KEY)) || 0;

    if (!accessToken) {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum);
      const signer = await ethersProvider.getSigner();

      const authClient = new AuthClient(signer, {
        serviceName: AppConfig.ETH_AUTH_SERVICE_NAME,
        environment: "browser"
      });

      const msg = await authClient.createLoginMessage();
      const accessToken = await this.sendRequest<AuthDto>({
        method: "POST",
        url: "/auth/login",
        data: msg
      });

      const { exp, token } = accessToken.data;
      const expMiliseconds = exp * 1000;

      CookieManager.set(GIRL_API_ACCESS_TOKEN_KEY, token, { expires: expMiliseconds });
      CookieManager.set(GIRL_API_TOKEN_EXP_KEY, exp.toString(), { expires: expMiliseconds });

      return { token, exp };
    }

    return { token: accessToken, exp: tokenExp };
  }
}
