import React from "react";

import Navbar from "@components/layoutElements/Navbar/Navbar";
import Footer from "@components/layoutElements/Footer/Footer";

import "./BaseLayout.scss";

interface BaseLayoutProps {
  children: React.ReactNode;
  withoutFooter?: boolean;
}

const BaseLayout = ({ children, withoutFooter = false }: BaseLayoutProps) => {
  return (
    <div className="base-layout">
      <header className="header-part">
        <Navbar />
      </header>

      <main className="main-part">{children}</main>

      {!withoutFooter && (
        <footer className="footer-part">
          <Footer />
        </footer>
      )}
    </div>
  );
};

export default BaseLayout;
