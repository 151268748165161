import { SvgIcon } from "@src/types/SvgIcon.types";

const UniswapSignet = ({ width = 44, height = 52, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 44 52"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M14.0107 18.6108C12.9867 17.1667 6.65809 8.24177 0.894162 1.13757C0.386577 0.511939 -0.0156025 0 0.00046519 0C0.0756782 0 17.0605 18.9446 18.2827 20.3918L18.6201 20.7913L18.5907 21.4127C18.5519 22.2324 18.2992 22.777 17.6953 23.3421C16.9991 23.9936 16.1522 24.3431 14.8221 24.5275C14.0063 24.6408 13.7095 24.7658 13.3798 25.1345C13.0637 25.4881 12.9429 25.9287 12.8556 27.0459C12.7205 28.7753 12.5207 29.5063 11.7147 31.2219C10.936 32.8792 10.778 33.5056 10.9142 34.3933C11.0803 35.4752 11.6815 36.2618 13.0892 37.2391C14.1635 37.9849 14.6509 38.4658 14.9186 39.0446C15.0831 39.4001 15.1045 39.5294 15.1013 40.1474C15.0965 41.092 14.947 41.4727 14.3101 42.1621C13.281 43.276 11.4512 44.1846 9.1978 44.7016C8.25618 44.9174 8.21462 44.9171 8.21462 44.696C8.21462 44.4181 7.86473 43.7078 7.25706 42.7523C6.55293 41.6447 6.27751 41.0908 6.01363 40.2522C5.71222 39.2945 5.62711 38.6473 5.67081 37.6454C5.71215 36.6978 5.81419 36.2775 6.18518 35.5272C6.47974 34.9312 6.67074 34.6603 7.83807 33.1818C9.83227 30.6564 10.5519 29.1805 10.8078 27.0916C11.036 25.2281 11.3523 24.3276 12.0596 23.5261C12.484 23.0455 12.5778 22.8618 12.5778 22.5125C12.5778 22.3408 11.2104 19.6566 9.21138 16.0242C6.02776 10.2396 6.02618 10.2365 6.11903 10.3256C6.1592 10.3641 7.63149 12.6637 9.39075 15.4359C11.69 19.0588 13.3786 21.5492 13.6253 21.7869C13.9745 22.1232 14.4539 22.3313 14.8795 22.3313C15.2558 22.3313 15.6328 22.2617 15.9984 22.0507C16.4515 21.7891 16.2262 21.5042 15.4667 20.5444L15.4665 20.5441C15.1244 20.1118 14.674 19.5425 14.1284 18.7767L14.0108 18.611L14.0107 18.6108Z"
        fill={color}
      />
      <path
        d="M16.9064 11.5424C16.6498 11.5825 16.6728 11.5925 17.218 11.6784C19.2042 11.9913 20.7558 12.7909 22.3348 14.3155C23.0297 14.9865 23.7283 15.8196 24.8586 17.3256C27.4034 20.716 28.4832 21.6607 30.6256 22.3699C31.8847 22.7869 33.1544 22.9815 35.398 23.1015C37.8821 23.2345 38.8202 23.4373 39.7072 24.0334C40.1995 24.3641 40.5836 24.7742 40.9663 25.3764L41.1884 25.7264L41.142 25.3414C40.9637 23.8647 39.925 21.7901 38.6029 20.2688L38.3619 19.9915L38.2402 20.5488C37.9337 21.9519 37.2727 22.9602 36.6586 22.961C36.3583 22.9615 36.0552 22.7669 35.9466 22.5039C35.8105 22.1744 35.8218 22.0524 36.0242 21.6674C36.3965 20.9598 36.4884 20.0125 36.3047 18.7767C36.2518 18.4195 36.1732 18.0847 36.1307 18.0327C35.9968 17.8695 34.9247 17.2345 34.2447 16.9155C31.7729 15.7559 28.8178 15.3792 25.9128 15.8533L25.2232 15.9658L24.7413 15.4208C22.927 13.3694 21.2273 12.2144 19.3593 11.7635C18.5592 11.5703 17.3981 11.4656 16.9064 11.5424Z"
        fill={color}
      />
      <path
        d="M37.0989 12.7582C36.828 13.3661 36.6932 14.0317 36.6367 15.0408C36.6004 15.6917 36.5641 15.9131 36.4893 15.9421C36.3776 15.9855 36.2259 15.9389 35.3062 15.5797C34.688 15.3382 33.1517 14.8828 33.107 14.9279C33.0955 14.9398 33.4169 15.0991 33.8212 15.2819C34.2257 15.4648 34.8834 15.8105 35.2822 16.0502C36.8573 16.9963 37.1261 17.5487 37.1282 19.8462C37.1292 20.9896 37.115 21.1415 36.9751 21.487C36.7879 21.9498 36.8206 22.0373 37.0275 21.6273C37.4102 20.8692 37.5686 20.1111 37.5914 18.9299C37.6061 18.1721 37.6285 18.0118 37.734 17.9051C37.8484 17.7893 37.8761 17.7876 38.0619 17.8847C38.3388 18.0295 39.5926 19.0422 40.1377 19.5615L40.5836 19.9861L40.1377 19.392C39.8926 19.0652 39.2161 18.304 38.6347 17.7005C37.3951 16.4139 37.297 16.242 37.1249 15.0509C36.9891 14.1121 37.0215 13.3052 37.2184 12.7153C37.291 12.4983 37.3376 12.3207 37.322 12.3207C37.3066 12.3207 37.2061 12.5176 37.0989 12.7582Z"
        fill={color}
      />
      <path
        d="M18.2772 18.9711C17.1703 17.4235 16.4854 15.0507 16.6336 13.277L16.6795 12.7281L16.9315 12.7748C17.4046 12.8624 18.2205 13.1708 18.6025 13.4064C19.6508 14.053 20.1047 14.9042 20.5664 17.0901C20.7017 17.7303 20.8791 18.4549 20.9607 18.7002C21.0922 19.095 21.5889 20.0172 21.9927 20.6162C22.2836 21.0475 22.0904 21.252 21.4474 21.193C20.4654 21.103 19.1353 20.1708 18.2772 18.9711Z"
        fill={color}
      />
      <path
        d="M28.299 23.4401C28.299 26.5379 30.1211 28.3738 35.2942 30.4889C38.739 31.8976 40.1481 32.626 41.1968 33.5412C42.1191 34.3462 42.6684 35.4206 42.7215 36.5243C42.7314 36.7302 42.7696 36.8815 42.8085 36.8687C42.9487 36.8225 43.4775 35.6209 43.6508 34.9542C43.8703 34.11 43.9496 32.5085 43.8143 31.6545C43.3484 28.7151 41.3949 26.5572 38.2376 25.4939C37.2441 25.1592 36.1672 24.9303 34.2538 24.6466C31.0018 24.165 29.8271 23.8 28.7781 22.9456C28.5524 22.7617 28.3524 22.6113 28.3334 22.6113C28.3147 22.6113 28.299 22.9843 28.299 23.4401Z"
        fill={color}
      />
      <path
        d="M31.6624 31.3676C31.5977 30.9759 31.4852 30.4759 31.4126 30.2562L31.2807 29.8567L31.5258 30.1361C31.8652 30.523 32.1333 31.0177 32.3604 31.6769C32.534 32.1798 32.5534 32.3294 32.552 33.147C32.5508 33.9493 32.5292 34.1175 32.3691 34.5704C32.1164 35.2843 31.803 35.7906 31.2773 36.3341C30.3324 37.3107 29.1173 37.8515 27.3642 38.0758C27.0594 38.1147 26.1713 38.1804 25.3903 38.2215C23.4225 38.3254 22.1273 38.5403 20.9635 38.9552C20.7962 39.0149 20.6468 39.0512 20.6317 39.0357C20.5846 38.9881 21.3769 38.5086 22.0314 38.1884C22.9542 37.737 23.8729 37.4905 25.9311 37.1425C26.9479 36.9706 27.9979 36.7619 28.2644 36.679C30.7824 35.895 32.0768 33.8718 31.6624 31.3676Z"
        fill={color}
      />
      <path
        d="M33.5652 31.3444C33.1887 32.6953 33.3464 34.1439 34.0339 35.6444C34.3788 36.3977 34.7228 36.9663 36.1256 39.1026C37.3323 40.9403 37.9299 42.0275 38.2232 42.9185C38.7017 44.3736 38.6296 45.681 37.9965 47.0252C37.847 47.343 37.7465 47.6023 37.7732 47.6018C37.8001 47.6011 38.0477 47.4764 38.3234 47.3246C40.2055 46.2874 41.5252 44.5102 42.0929 42.2474C42.273 41.5299 42.3439 39.8151 42.2227 39.1024C42.0093 37.8443 41.5656 36.8126 40.8485 35.9068C40.0298 34.8723 38.8258 33.967 36.7138 32.7978C35.0216 31.8608 34.3863 31.4962 34.0697 31.2796C33.9104 31.1709 33.7483 31.0817 33.7094 31.0817C33.6702 31.0817 33.6056 31.1999 33.5652 31.3444Z"
        fill={color}
      />
      <path
        d="M14.6035 43.7397C16.5232 42.0937 18.9117 40.9246 21.0874 40.5657C22.0251 40.4108 23.5871 40.4722 24.4555 40.6979C25.8472 41.0597 27.0921 41.8697 27.7397 42.8349C28.3726 43.7784 28.644 44.6006 28.9267 46.4295C29.0382 47.1511 29.1593 47.8756 29.1961 48.0397C29.4084 48.9875 29.8213 49.7454 30.3333 50.1258C31.1463 50.7302 32.5462 50.7676 33.9233 50.2222C34.1572 50.1296 34.3601 50.0656 34.3743 50.0799C34.4243 50.1304 33.7308 50.6019 33.2416 50.8501C32.5832 51.1838 32.0599 51.3128 31.3643 51.3128C30.1031 51.3128 29.056 50.6613 28.182 49.3329C28.0101 49.0714 27.6236 48.2881 27.3231 47.5924C26.4006 45.4556 25.9451 44.8045 24.8739 44.0921C23.9417 43.4722 22.7394 43.3613 21.835 43.8115C20.647 44.4031 20.3155 45.9449 21.1664 46.922C21.5046 47.3101 22.1352 47.6451 22.6509 47.7103C23.6157 47.8321 24.4446 47.0868 24.4446 46.0977C24.4446 45.4553 24.2016 45.089 23.5897 44.8084C22.7539 44.4256 21.8555 44.8732 21.8598 45.6704C21.8616 46.0104 22.0074 46.2241 22.3431 46.3783C22.5584 46.4771 22.5634 46.4851 22.3878 46.4478C21.6209 46.2864 21.4413 45.3475 22.0579 44.7243C22.7981 43.9761 24.329 44.3062 24.8545 45.3277C25.0754 45.7567 25.1011 46.611 24.9086 47.1269C24.4776 48.2816 23.2212 48.8887 21.9466 48.5583C21.0788 48.3333 20.7254 48.0897 19.6792 46.9955C17.8611 45.0936 17.1553 44.725 14.5343 44.3096L14.032 44.2298L14.6035 43.7397Z"
        fill={color}
      />
      <path
        d="M19.4595 24.8535C18.8351 25.0483 18.2281 25.7203 18.0402 26.425C17.9256 26.855 17.9907 27.609 18.1624 27.8419C18.4397 28.218 18.708 28.3171 19.4343 28.312C20.8563 28.3018 22.0924 27.6824 22.2361 26.9084C22.3539 26.2735 21.811 25.3941 21.0632 25.0079C20.6773 24.8088 19.8566 24.7298 19.4595 24.8535Z"
        fill={color}
      />
    </svg>
  );
};

export default UniswapSignet;
