import React from "react";

import { formatWalletAddress } from "@utils/blockchain/formatWalletAddress";
import { copyToClipboard } from "@utils/copyToClipboard";

import "./WalletAddress.scss";

interface WalletAddressProps {
  address: string;
}

const WalletAddress = ({ address }: WalletAddressProps) => {
  const formattedWalletAddress = formatWalletAddress(address);

  const handleCopyToClibpoard = () => copyToClipboard(address);

  return (
    <div title={address} onClick={handleCopyToClibpoard} className="wallet-address">
      <p className="wallet-address-addr">{formattedWalletAddress}</p>
      <img
        src="/images/layouts/wallet-address-icon.svg"
        alt="wallet address"
        width={20}
        height={20}
        className="wallet-address-status-icon"
      />
    </div>
  );
};

export default WalletAddress;
