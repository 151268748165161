import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { reloadApp } from "@utils/reloadApp";

import Button from "@components/toolkit/buttons/Button/Button";
import ReloadIcon from "@components/assets/ReloadIcon";

import "./Fallback.scss";

interface FallbackProps {
  id?: string;
  title?: string;
  description?: string;
  withReload?: boolean;
  action?(): void;
  className?: string;
}

const Fallback = ({ id, title, description, withReload = true, action, className }: FallbackProps) => {
  const { t } = useTranslation(["apiResponse", "common"]);

  const handleRealod = () => {
    action ? action() : reloadApp();
  };

  const errorTitle = title || t("apiResponse:error.base");
  const classes = classNames("fallback", className);

  return (
    <div id={id} className={classes}>
      <img src="/images/layouts/base-error.svg" alt="error" width={220} height={280} />

      <div className="fallback-content">
        <h3 className="grey-6">{errorTitle}</h3>

        {description && <p className="p1 description">{description}</p>}
        {withReload && (
          <Button variant="tertiary" onClick={handleRealod}>
            <ReloadIcon />
            {t("common:tryAgain")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Fallback;
