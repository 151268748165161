import { SvgIcon } from "@src/types/SvgIcon.types";

const HamburgerIcon = ({ width = 48, height = 48, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M12 32H36M12 24H36M12 16H36"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HamburgerIcon;
