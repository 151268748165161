import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useBlockScroll from "@hooks/useBlockScroll";
import useClickKey from "@hooks/useClickKey";

// import ClickAwayListener from "@components/common/ClickAwayListener/ClickAwayListener";
import ModalButton from "@components/toolkit/buttons/ModalButton/ModalButton";
import CloseIcon from "@components/assets/CloseIcon";

import "./Modal.scss";

interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;
}

const Modal = ({ children, onClose }: ModalProps) => {
  const { handleLocked, handleUnlocked } = useBlockScroll();
  const { t } = useTranslation("common");

  const handleClose = () => {
    onClose?.();
  };

  useClickKey("Escape", handleClose);

  useEffect(() => {
    handleLocked();

    return () => {
      handleUnlocked();
    };
  }, []);

  return (
    // <ClickAwayListener mouseEvent="mouseup" onClickAway={handleClose}>
    <div className="modal">
      <div className="modal-content">{children}</div>

      {onClose && (
        <div className="modal-close-box">
          <ModalButton variant="tertiary" onClick={handleClose} className="modal-close-btn">
            <CloseIcon /> {t("close")}
          </ModalButton>
        </div>
      )}
    </div>
    // </ClickAwayListener>
  );
};

export default Modal;
