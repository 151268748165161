import { Categories } from "../api.dtos";

export const girlCategories = [
  {
    category: Categories.NICE_LADIES,
    tKey: "common:girlCategories.niceLadies"
  },
  {
    category: Categories.BAD_LADIES,
    tKey: "common:girlCategories.badLadies"
  },
  {
    category: Categories.SECRETARY_TEACHER,
    tKey: "common:girlCategories.secretaryTeacher"
  },
  {
    category: Categories.BUSINESSWOMAN,
    tKey: "common:girlCategories.businesswoman"
  },
  {
    category: Categories.SCIENCE_FICTION_MOVIES,
    tKey: "common:girlCategories.scienceFictionMovies"
  },
  {
    category: Categories.GODDESS,
    tKey: "common:girlCategories.goddess"
  },
  {
    category: Categories.ORIGINALS_AI_WOMEN,
    tKey: "common:girlCategories.originalsAiWoman"
  }
];

export const getTKeyGirlCategory = (category: string) => {
  return girlCategories.find((item) => item.category === category)?.tKey;
};
