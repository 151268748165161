import { SvgIcon } from "@src/types/SvgIcon.types";

const Twitter = ({ width = 48, height = 48, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M35.4514 6H41.558L28.2165 21.2499L43.9127 41.9973H31.6232L21.9987 29.4124L10.9836 41.9973H4.87371L19.1445 25.6854L4.08716 6.00166H16.6886L25.3889 17.5046L35.4514 6ZM33.3092 38.3433H36.6927L14.85 9.46316H11.2193L33.3092 38.3433Z"
        fill={color}
      />
    </svg>
  );
};

export default Twitter;
