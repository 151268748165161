type CookieOptions = {
  expires?: Date | number | string;
  path?: string;
  domain?: string;
  secure?: boolean;
};

export class CookieManager {
  public static get(name: string): string | null {
    const cookies = document.cookie.split("; ");

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }

    return null;
  }

  public static set(name: string, value: string, options?: CookieOptions): void {
    let cookieString = `${name}=${encodeURIComponent(value)}`;

    if (options) {
      if (options.expires) {
        let expires: Date;
        if (typeof options.expires === "number") {
          expires = new Date(options.expires);
        } else if (typeof options.expires === "string" || options.expires instanceof Date) {
          expires = new Date(options.expires);
        } else {
          throw new Error("Invalid expires option");
        }
        cookieString += `; expires=${expires.toUTCString()}`;
      }
      if (options.path) {
        cookieString += `; path=${options.path}`;
      }
      if (options.domain) {
        cookieString += `; domain=${options.domain}`;
      }
      if (options.secure) {
        cookieString += `; secure`;
      }
    }

    document.cookie = cookieString;
  }

  public static remove(name: string, options?: CookieOptions): void {
    this.set(name, "", { ...options, expires: -1 });
  }
}
