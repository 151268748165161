import React, { useMemo } from "react";

import { useGirlsData } from "@contexts/GirlsData.context";

import BaseLayout from "@components/layouts/BaseLayout/BaseLayout";
import StickySidebarLayout from "@components/layouts/StickySidebarLayout/StickySidebarLayout";
import LoadingScreen from "@app/LoadingScreen/LoadingScreen";

import Hero from "./sections/Hero/Hero";
import TopTenGirls from "./sections/TopTenGirls/TopTenGirls";
import Categories from "./sections/Categories/Categories";
// import MediaAboutUs from "./sections/MediaAboutUs/MediaAboutUs";
import ReadArticle from "./sections/ReadArticle/ReadArticle";
import Footer from "@components/layoutElements/Footer/Footer";
import Fallback from "@components/common/Fallback/Fallback";

import "./GirlsPage.scss";

const GirlsPage = () => {
  const { loadingGirlsData, girlsData, topTenGirls, categorizedGirls } = useGirlsData();

  const downlaodGirlsError = useMemo(
    () => girlsData.error || topTenGirls.error || categorizedGirls.error,
    [girlsData, topTenGirls, categorizedGirls]
  );

  if (loadingGirlsData) return <LoadingScreen />;

  return (
    <BaseLayout withoutFooter>
      <div className="girls-page">
        <Hero />
        <StickySidebarLayout>
          {!downlaodGirlsError && (
            <>
              <TopTenGirls />
              <Categories />
            </>
          )}

          {downlaodGirlsError && <Fallback id="girls-page-fallback" className="my-4 py-12" />}

          {/* <MediaAboutUs /> */}
          <ReadArticle />
          <Footer />
        </StickySidebarLayout>
      </div>
    </BaseLayout>
  );
};

export default (
  <>
    <GirlsPage />
  </>
);
