import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import "./ToolbarNavItem.scss";

interface ToolbarNavItemProps {
  to: string;
  disabled?: boolean;
  comingSoon?: boolean;
  children: React.ReactNode | React.ReactElement;
}

const ToolbarNavItem = ({ to, disabled, comingSoon, children }: ToolbarNavItemProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const navigateTo = () => navigate(to);

  const classes = classNames("toolbar-nav-item", {
    "toolbar-nav-item--active": location.pathname === to,
    "toolbar-nav-item--soon": comingSoon,
    disabled
  });

  return (
    <button disabled={disabled} className={classes} onClick={navigateTo}>
      {children}
      {comingSoon && <span className="coming-soon">{t("soon")}</span>}
    </button>
  );
};

export default ToolbarNavItem;
