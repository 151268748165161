import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";

import { AuthDto } from "@services/api/api.dtos";
import { IContext } from "@src/types/IContext.types";
import { GIRL_API_ACCESS_TOKEN_KEY, GIRL_API_TOKEN_EXP_KEY } from "@services/api/GirlApiClient";

import { useWallet } from "@services/metamask/WalletProvider.context";
import { girlApiClient } from "@services/api/girlApiClient.instance";
import { CookieManager } from "@utils/browser/CookieManager";

export type AuthStatus = "logging" | "loggedIn" | "loggedOut" | "error";

interface ContextValue {
  accessToken: AuthDto | null;
  tokenExpired: boolean;
  authStatus: AuthStatus;
  authError: AxiosError<unknown, any> | null;
  login: () => Promise<void>;
  logout: () => void;
  changeAuthStatus: (status: AuthStatus) => void;
}

const AuthContext = React.createContext(null as any);

export const AuthProvider = ({ children }: IContext) => {
  const [accessToken, setAccessToken] = useState<AuthDto | null>(null);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [authStatus, setAuthStatus] = useState<AuthStatus>("loggedOut");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authError, setAuthError] = useState<AxiosError | null>(null);

  const { metamaskStatus } = useWallet();

  const login = async () => {
    setAuthStatus("logging");

    const token = await girlApiClient.getJWT();

    setAccessToken(token);
    setTokenExpired(false);
    setAuthStatus("loggedIn");
  };

  const logout = () => {
    setAccessToken(null);
    setTokenExpired(false);
    setAuthStatus("loggedOut");

    CookieManager.remove(GIRL_API_ACCESS_TOKEN_KEY);
    CookieManager.remove(GIRL_API_TOKEN_EXP_KEY);
  };

  const changeAuthStatus = (status: AuthStatus) => setAuthStatus(status);

  useEffect(() => {
    const token = CookieManager.get(GIRL_API_ACCESS_TOKEN_KEY) || "";
    const exp = Number(CookieManager.get(GIRL_API_TOKEN_EXP_KEY)) || 0;

    if (token && metamaskStatus === "connected") {
      setAccessToken({ token, exp });
      setAuthStatus("loggedIn");
    }
  }, [metamaskStatus]);

  useEffect(() => {
    if (accessToken) {
      const delay = accessToken.exp * 1000 - new Date().getTime() - 1000;

      const expTokenTimer = setTimeout(() => {
        setAccessToken(null);
        setTokenExpired(true);
      }, delay);

      return () => clearTimeout(expTokenTimer);
    }
  }, [accessToken]);

  const contextValue: ContextValue = {
    accessToken,
    tokenExpired,
    authStatus,
    authError,
    login,
    logout,
    changeAuthStatus
  };
  1707918109000;
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): ContextValue => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
