import { SvgIcon } from "@src/types/SvgIcon.types";

const ArrowUpRight = ({ width = 16, height = 16, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M12 4H7.55579M12 4L11.9998 8.44444M12 4L4 12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpRight;
