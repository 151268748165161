import { SvgIcon } from "@src/types/SvgIcon.types";

const Telegram = ({ width = 48, height = 48, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3698 34.212C23.7596 35.8147 22.1634 37.3909 20.582 38.9794C20.0291 39.5358 19.4075 39.8434 18.614 39.8007C18.0723 39.7712 17.7694 39.5282 17.6031 39.0031C16.3915 35.1605 15.1623 31.3274 13.9572 27.4811C13.8401 27.1079 13.6673 26.9266 13.2975 26.8098C10.4431 25.9191 7.59437 25.0076 4.75026 24.0838C4.31263 23.9414 3.87129 23.7553 3.49313 23.4923C2.90869 23.0878 2.82321 22.4279 3.34725 21.9531C3.83133 21.5154 4.40926 21.1337 5.00949 20.8907C8.49843 19.4778 12.0059 18.1105 15.5079 16.7309C24.3952 13.2245 33.2824 9.72086 42.1706 6.2144C43.8607 5.54691 45.1597 6.4869 44.9841 8.35455C44.8717 9.54901 44.5688 10.7245 44.3244 11.9037C42.3946 21.2154 40.4629 30.528 38.5228 39.8377C38.0619 42.0481 36.5428 42.6254 34.7626 41.2838C31.7809 39.0382 28.8021 36.7851 25.8223 34.5358C25.6802 34.4304 25.5324 34.3288 25.3698 34.212ZM18.6772 37.0396C18.7107 37.0301 18.7441 37.0206 18.7776 37.012C18.7961 36.9247 18.8231 36.8392 18.8305 36.7528C19.0219 34.6421 19.2245 32.5323 19.3908 30.4178C19.428 29.9469 19.5859 29.6127 19.9325 29.2955C22.6809 26.7841 25.4126 24.2566 28.1498 21.7347C31.1798 18.9423 34.2106 16.1527 37.2294 13.3489C37.4162 13.1761 37.4942 12.8817 37.6225 12.6424C37.3382 12.6111 37.0334 12.4943 36.7751 12.5627C36.4229 12.6557 36.0894 12.8646 35.7753 13.0669C29.0883 17.3709 22.4068 21.6825 15.7151 25.978C15.3369 26.2211 15.2663 26.4271 15.4103 26.8515C15.9037 28.2995 16.3525 29.7608 16.818 31.2192C17.4377 33.1599 18.0575 35.0998 18.6772 37.0396Z"
        fill={color}
      />
    </svg>
  );
};

export default Telegram;
