import { APP_VERSION } from "@config/config";

const WELCOME_MESSAGE = "Welcome to AI Girls";

export const welcome = () => {
  const styles = ["font-size: 16px", `color: rgb(245, 2, 116)`, "border-radius: 8px", "padding: 8px"].join(";");

  // eslint-disable-next-line no-console
  console.log(`%c ${WELCOME_MESSAGE} - ${APP_VERSION}`, styles);
};
